@font-face {
	font-family: 'opendyslexic3';
	src: local('opendyslexic3'), url(../../assets/fonts/OpenDyslexic3-Regular.ttf) format('truetype');
}

#chapter-article {
	position: relative;
	background-color: var(--bg-color-sub);
	overflow: hidden;
}

#chapter-article .content-wrap {
	max-width: 100%;
	position: relative;
	padding: 0 .725rem;
	padding-bottom: 30px;
}

#chapter-article .recommends {
	padding-top: 16px;
	padding-bottom: 16px;
	border-top: 2px solid var(--border-color-3);
}

#chapter-article .recommends h3 {
	font-size: 24px;
	font-weight: 700;
	margin-bottom: 20px;
}

#chapter-article .page-in .titles {
	width: 100%;
	padding: 20px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	z-index: 1;
	border-bottom: 2px solid var(--border-color-3);
	border-top: 1px solid var(--border-color-3);
}

#chapter-article .page-in .titles h1 {
	margin: 0;
	font-weight: 400;
	display: block;
	max-width: 100%;
	min-width: 0;
	flex-grow: 1;
	line-height: 1.3;
}

#chapter-article .page-in .titles h1 .booktitle {
	max-width: 100%;
	font-size: 1rem;
	display: inline-block;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

#chapter-article .page-in .titles h1 .chapter-title {
	font-size: .925rem;
	display: inline-block;
}

#chapter-article .page-in .titles .control-action-btn {
	display: none;
}

#chapter-article .chapter-content {
	font-size: 16px;
	line-height: 1.6;
	font-family: "Nunito Sans", sans-serif;
	padding-top: 1rem;
	padding-bottom: 1rem;
	word-break: break-word;
	position: relative;
}

#chapter-article .chapter-content.font_roboto {
	font-family: "Roboto", sans-serif;
}

#chapter-article .chapter-content.font_lora {
	font-family: "Lora", serif, serif;
}


#chapter-article .chapter-content.font_dyslexic {
	font-family: "opendyslexic3";
	text-rendering: optimizeLegibility;
}

#chapter-article .chapter-content p.tts-highlight {
	background-color: var(--bg-color-4);
}


#chapter-article .chapter-content img {
	max-width: 100%;
}

#chapter-article .chapter-content p {
	margin-top: 1rem;
	margin-bottom: 1rem;
}

#chapter-article .chapter-content table {
	width: 100%;
	border-collapse: collapse;
	border: 2px solid var(--border-color-3);
}

#chapter-article .chapter-content table th,
#chapter-article .chapter-content table td {
	border: 2px solid var(--border-color-3);
	padding: 4px;
}
#chapter-article .chapter-content table td p {
	margin: 10px;
}

#chapter-article .chapternav {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	margin: 30px auto;
	margin-bottom: 0px;
	padding: 10px;
}

#chapter-article .chapternav a {
	border-radius: 4px;
	font-size: 1rem;
	line-height: 2;
	font-weight: 400;
}

#chapter-article .chapternav a:not(:last-child) {
	margin-right: 10px;
}

#chapter-article .chapternav a i {
	font-size: 1rem;
	line-height: 1;
	margin-right: .3rem;
}

#chapter-article .chapternav a span {
	text-transform: uppercase;
}

#chapter-article .chapternav a span+i {
	margin-right: 0;
	margin-left: .3rem;
}

#chapter-article #info {
	margin: 0 auto 10px;
}

#chapter-article .loadfullcont {
	background-color: #fcd9e2;
	border: 1px solid #fbc6d3;
	text-align: center;
	padding: 20px;
	border-radius: 6px;
}

#chapter-article .loadfullcont.rm {
	border: none;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	text-align: center;
	margin: 0;
	padding: 100px 0;
	background-color: transparent;
	background: #fff;
	background: -moz-linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .8) 30%, #fff 90%);
	background: -webkit-linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .8) 30%, #fff 90%);
	background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .8) 30%, #fff 90%);
}

#chapter-article .loadfullcont.rm button {
	height: 50px;
	padding: 10px 20px;
}

#chapter-article .loadfullcont.svg {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
}

#chapter-article .loadfullcont.svg svg {
	height: 6rem;
	width: 6rem;
	vertical-align: middle;
}

#chapter-article .loadfullcont.svg svg [fill]:not(.permanent):not(g) {
	transition: fill .3s ease;
	fill: #f1416c;
}

#chapter-article .loadfullcont .memberonly p {
	margin: 0 0 6px 0;
	font-size: .875rem;
	color: #515355;
}

#chapter-article .loadfullcont .memberonly p strong {
	color: #912741;
	font-size: 1rem;
}

#chapter-article .loadfullcont .memberonly a {
	font-size: 1rem;
}

#chapter-article .loadfullcont .memberonly a.button {
	margin-top: .5rem;
}

#chapter-article .left-panel,
#chapter-article .right-panel {
	display: none;
}

#chapter-article .guide-message {
	position: fixed;
	bottom: 0;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	padding: 30px 30px;
	background: rgba(0, 0, 0, .8);
	color: #fff;
	border-radius: 10px;
	text-align: center;
	font-size: 1rem;
	display: none;
	margin-bottom: 20%;
}

#chapter-article .guide-message .mobile {
	display: block;
}

#chapter-article .guide-message .desktop {
	display: none;
}

.control-action {
	display: none;
	position: fixed;
	bottom: 0;
	right: 0;
	left: 0;
	width: 100%;
	background-color: #121212;
	z-index: 2147483647;
	padding: 10px 10px 20px;
}

.control-action.btspace {
	padding-bottom: 126px;
}

.control-action .option-select {
	width: 100%;
}

.control-action .option-select .option-wrap {
	display: flex;
	padding: 13px 0 13px;
	position: relative;
}

.control-action .option-select .option-wrap input[type=radio] {
	opacity: 0;
	position: fixed;
	width: 0;
}

.control-action .option-select .option-wrap label {
	flex: 1;
	display: inline-block;
	background-color: var(--bg-color-secondary);
	color: #b3b4bf;
	padding: .3rem .2rem;
	font-size: .85rem;
	border-radius: 4px;
	text-align: center;
}

.control-action .option-select .option-wrap label:not(:last-child) {
	margin-right: .5rem;
}

.control-action .option-select .option-wrap label:hover {
	background-color: var(--bg-color-secondary-2);
}

.control-action .option-select .option-wrap input[type=radio]:checked+label {
	background-color: var(--bg-color-secondary-2);
	color: #e1e1e1;
}

.control-action .option-select .combo-wrap {
	display: flex;
}

.control-action .option-select .combo-wrap label {
	color: #a7a7a7;
}

.control-action .option-select .combo-wrap .combo-select {
	display: flex;
	flex: 1;
	margin-left: 0.5rem;
	margin-right: 0.5rem;
	position: relative;
	
}

.control-action .option-select .combo-wrap button {
	border: none;
	flex: 1 1;
	background-color: var(--bg-color-secondary);
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.control-action .option-select .combo-wrap button:hover {
	background-color: var(--bg-color-secondary-2);
}

.control-action .option-select .combo-wrap button .color-swatch {
	display: inline-block;
	height: 90%;
	aspect-ratio: 1/1;
	border-radius: 20%;
	border: var(--bg-color-secondary-2) 1px solid;
}

.control-action .option-select .combo-wrap .combo-select .combo-dialog {
	display: flex;
	flex-direction: column;
	background-color: #121212;
	border: black 2px solid;
	opacity: 1;
	position: absolute;
	z-index: 2;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	width: 130px;
	gap: 10px;
	padding: 8px;
	bottom: 130%;
	top: auto;
}



.control-action .option-select .combo-wrap .combo-select .combo-dialog::before {
	content: "";
	position: absolute;
	top: calc(100% + 3px);
	left: calc(50% - 5px);
	width: 0;
	height: 0;
	border-top: 5px solid var(--bg-color-secondary);
	border-bottom: none;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
}

.control-action .option-select .combo-wrap .combo-select .combo-dialog .background {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: var(--bg-color-secondary);
	z-index: -1;
}

.control-action .option-select .combo-wrap .combo-select .combo-dialog {
	transform-origin: center bottom 0px;
	transition: transform 0.15s ease 0s;
	transform: scaleY(1) translateX(-50%);
	left: 50%;
}

.control-action .option-select .combo-wrap .combo-select .combo-dialog .color-button {

	width: 20px;
	height: 20px;
	border-radius: 10%;
	cursor: pointer;
	margin-right: 0;
}

.control-action .option-select .combo-wrap .combo-select .combo-dialog .color-button:hover {
	transform: scale(1.4);
}

.control-action .option-select .combo-wrap .combo-select .combo-dialog #custom-hex-input {
	width: 100%;
	padding: 5px;
	background-color: var(--bg-color-2);
	border: 1px solid var(--text-color);
	border-radius: 5px;
	width: 100%;
	height: 22px;
	color: var(--text-color);
	grid-column: span 4;
}

.control-action .lang-select {
	width: 100%;
	display: flex;
	flex: 1;
	margin-bottom: 13px;
	padding-top: 16px;
	position: relative;
}

.control-action .lang-select:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	border-top: 2px solid #272727;
}

.control-action .lang-select:after {
	content: "Translation";
	position: absolute;
	top: -5px;
	left: calc(50% - 39px);
	width: 78px;
	font-size: 10px;
	text-align: center;
	color: #a7a7a7;
	background-color: #121212;
}

.control-action .lang-select .language-combo {
	background-color: #272727;
	color: #a7a7a7;
	border-color: #373737;
	font-size: .85rem;
	padding: 5px 8px;
	flex: 1 1 50%;
}

.control-action .lang-select a {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 5px;
	border: 1px solid transparent;
	height: 100%;
	padding: 3px 10px;
	margin-left: 1rem;
	text-align: center;
	font-size: 14px;
	background-color: var(--bg-color-secondary-2);
	color: #e1e1e1;
}

.control-action .lang-select .flag-wrap {
	display: flex;
	justify-content: space-between;
	padding-bottom: .5rem;
}

.control-action .lang-select .flag-wrap img {
	width: 30px;
	height: 23px;
	padding: 4px;
	opacity: .5;
	cursor: pointer;
}

.control-action .lang-select .flag-wrap img:not(:first-of-type) {
	margin-left: .15rem;
}

.control-action .lang-select .flag-wrap img.lang_active {
	opacity: 1;
	border: 1px solid #515355;
}

.control-action .action-items {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	flex-direction: column;
	padding: 0;
}

.control-action .action-items .action-select {
	width: 100%;
	--gap: 6px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border: 1px solid #272727;
}

.control-action .action-items .action-select a,
.control-action .action-items .action-select button {
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
	text-decoration: none;
	font-size: 1.2rem;
	line-height: 2.4rem;
	width: auto;
	text-align: center;
	position: relative;
	display: block;
	-webkit-transition: background-color .2s, color .2s;
	-o-transition: background-color .2s, color .2s;
	transition: background-color .2s, color .2s;
	color: #a7a7a7;
	border-right: 1px solid #272727;
	border-left: 1px solid #272727;
	border-top: none;
	border-bottom: none;
	margin-right: -1px;
	-webkit-box-ordinal-group: 2;
	-ms-flex-order: 1;
	order: 1;
	background-color: transparent;
}

.control-action .action-items .action-select a svg,
.control-action .action-items .action-select button svg {
	color: #b3b4bf;
	width: 1.4rem;
	height: 1.4rem;
}

.control-action .action-items .action-select a svg path,
.control-action .action-items .action-select button svg path {
	fill: currentColor;
	fill-opacity: 1;
}

.control-action .action-items .action-select a i,
.control-action .action-items .action-select button i {
	font-size: 1.2rem;
}

.control-action .action-items .action-select a.chnav span,
.control-action .action-items .action-select button.chnav span {
	display: none;
}

.control-action .action-items .action-select a.next,
.control-action .action-items .action-select button.next {
	-webkit-box-ordinal-group: 3;
	-ms-flex-order: 2;
}

.control-action .action-items .action-select a.prev,
.control-action .action-items .action-select button.prev {
	-webkit-box-ordinal-group: 1;
	-ms-flex-order: 0;
}

.control-action .action-items .action-select a.fill,
.control-action .action-items .action-select button.fill {
	font-size: 1.2rem;
	line-height: .4rem;
	border: none;
	margin: 0;
	height: 100%;
	padding: 5px;
	text-align: center;
	flex-wrap: wrap;
}

.control-action .action-items .action-select a.fill small,
.control-action .action-items .action-select button.fill small {
	font-size: .725rem;
}

.control-action .action-items .action-select a.desktop,
.control-action .action-items .action-select button.desktop {
	display: none;
}

.control-action .action-items .action-select.range-slider {
	margin: 13px 0;
	border: none;
}

.control-action .action-items .action-select.range-slider svg {
	color: #b3b4bf;
	width: 1.6rem;
	height: 1.3rem;
	margin-bottom: 1rem;
}

.control-action .action-items .action-select.range-slider svg path {
	fill: currentColor;
	fill-opacity: 1;
}

.control-action .fly-in .tools li {
	display: inline-block;
}

.control-action .fly-in .tools a.chnav {
	color: #fff;
	font-size: 20px;
}

.control-action .fly-in .tools i {
	vertical-align: middle;
}

.control-action .fly-in .tools i.small {
	font-size: 10px;
}

.control-action .fly-in .tools i.fa-sun {
	color: #fbfe89;
}

.mobile-title-bar {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	padding: 16px .725rem;
	margin: 0;
	background-color: #121212;
	border: 0;
	border-bottom: 1px solid #272727;
	z-index: 2147483647;
}

.mobile-title-bar .bar-body {
	display: flex;
	align-items: center;
}

.mobile-title-bar .bar-body .bar-nav-back {
	display: inline-block;
	color: #a7a7a7;
	text-align: center;
	height: 2rem;
	width: 2rem;
	position: relative;
}

.mobile-title-bar .bar-body .bar-nav-back svg {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	stroke-width: 0;
	stroke: currentColor;
	fill: #a7a7a7;
	vertical-align: middle;
	display: inline-block;
	width: auto;
	height: auto;
}

.mobile-title-bar .bar-body .bar-titles {
	padding-left: 20px;
	flex-grow: 1;
	flex-shrink: 1;
	display: flex;
	flex-direction: column;
}

.mobile-title-bar .bar-body .bar-titles .booktitle {
	font-size: .875rem;
	color: var(--anchor-color);
}

.mobile-title-bar .bar-body .bar-titles .chapter-title {
	font-size: .875rem;
	color: #a7a7a7;
}

.range-fontsize {
	width: 100%;
	margin: 0 14px;
}

.range-fontsize .range {
	position: relative;
	width: 100%;
	height: 4px;
}

.range-fontsize .range input {
	width: 100%;
	position: absolute;
	top: 2px;
	height: 0;
	margin: 0;
}

.range-fontsize .range input::-webkit-slider-thumb {
	-webkit-appearance: none;
	width: 14px;
	height: 14px;
	margin: -6px 0 0;
	border-radius: 50%;
	background: var(--anchor-color);
	cursor: pointer;
	border: 0 !important;
}

.range-fontsize .range input::-moz-range-thumb {
	width: 14px;
	height: 14px;
	margin: -6px 0 0;
	border-radius: 50%;
	background: var(--anchor-color);
	cursor: pointer;
	border: 0 !important;
}

.range-fontsize .range input::-ms-thumb {
	width: 14px;
	height: 14px;
	margin: -6px 0 0;
	border-radius: 50%;
	background: var(--anchor-color);
	cursor: pointer;
	border: 0 !important;
}

.range-fontsize .range input::-webkit-slider-runnable-track {
	width: 100%;
	height: 2px;
	cursor: pointer;
	background: #b2b2b2;
}

.range-fontsize .range input::-moz-range-track {
	width: 100%;
	height: 2px;
	cursor: pointer;
}

.range-fontsize .range input::-ms-track {
	width: 100%;
	height: 2px;
	cursor: pointer;
	background: #b2b2b2;
}

.range-fontsize .range input:focus {
	background: none;
	outline: none;
}

.range-fontsize .range input::-ms-track {
	width: 100%;
	cursor: pointer;
	background: transparent;
	border-color: transparent;
	color: transparent;
}

.range-fontsize .range-labels {
	margin: 8px 0 0;
	padding: 0;
	list-style: none;
	display: flex;
	justify-content: space-between;
}

.range-fontsize .range-labels li {
	position: relative;
	text-align: center;
	color: #b2b2b2;
	font-size: 12px;
	cursor: pointer;
	line-height: 1.2;
}

.range-fontsize .range-labels li::before {
	position: absolute;
	top: -14px;
	right: 0;
	left: 0;
	content: "";
	margin: 0 auto;
	width: 8px;
	height: 8px;
	background: #b2b2b2;
	border-radius: 50%;
}

.range-fontsize .range-labels .active {
	color: var(--anchor-color);
	font-weight: 600;
}

.range-fontsize .range-labels .selected::before {
	background: var(--anchor-color);
}

.range-fontsize .range-labels .active.selected::before {
	display: none;
}

@media screen and (min-width:768px) {
	#chapter-article {
		position: relative;
		background-color: var(--bg-color);
	}

	#chapter-article .sidebar {
		width: 400px;
	}

	#chapter-article .sidebar .chapter-list .chapter-no {
		flex-basis: 20%;
	}

	#chapter-article .recommends {
		padding: 30px 19px;
	}


	#chapter-article .content-wrap {
		max-width: 780px;
		margin: 0 auto;
		position: relative;
		padding: 0;
	}

	#chapter-article .chapter-content {
		font-family: "Nunito Sans", sans-serif;
		font-size: 18px;
		line-height: 1.7;
		padding: 30px 19px 40px;
		background-color: var(--bg-color-2);
		border: 2px solid var(--border-color-3);
		border-top: none;
		position: relative;
	}

	#chapter-article .chapter-content p {
		margin-top: 20px;
		margin-bottom: 20px;
	}


	#chapter-article .chapternav {
		margin: 0 auto 30px;
		padding: 30px;
		justify-content: center;
		background-color: var(--bg-color-2);
		border: 2px solid var(--border-color-3);
		border-top: none;
		margin-bottom: 0px;
	}

	#chapter-article .chapternav a {
		font-size: 16px;
		white-space: nowrap;
		padding: 8px 20px;
	}

	#chapter-article .chapternav a:not(:last-child) {
		margin-right: 16px;
	}

	#chapter-article #info {
		margin: 0 auto 30px;
		padding: 30px;
		background-color: var(--bg-color-2);
		border: 2px solid var(--border-color-3);
		border-top: none;
	}

	#chapter-article .page-in .titles {
		background-color: var(--bg-color-2);
		border: 2px solid var(--border-color-3);
		border-bottom-width: 2px;
		border-top-width: 1px;
		padding: 20px 19px;
	}

	#chapter-article .page-in .titles h1 .booktitle {
		font-size: 20px;
	}

	#chapter-article .page-in .titles h1 .chapter-title {
		font-size: 18px;
	}

	#chapter-article .page-in .titles .control-action-btn {
		display: block;
		line-height: 1;
		padding-left: 20px;
	}

	#chapter-article .page-in .titles .control-action-btn button {
		font-size: 36px;
		border: none;
		background-color: transparent;
	}

	#chapter-article .loadfullcont.svg {
		flex-direction: row;
		text-align: left;
		border-radius: 0;
	}

	#chapter-article .loadfullcont.svg svg {
		margin-right: 20px;
	}

	#chapter-article .loadfullcont .memberonly p {
		font-size: 16px;
	}

	#chapter-article .loadfullcont .memberonly p strong {
		font-size: 18px;
	}

	#chapter-article .loadfullcont .memberonly a {
		font-size: 18px;
	}

	#chapter-article .loadfullcont .memberonly a.button {
		margin-top: .5rem;
	}

	#chapter-article .guide-message {
		font-size: 1.4rem;
	}

	#chapter-article .guide-message .mobile {
		display: none;
	}

	#chapter-article .guide-message .desktop {
		display: block;
	}

	.control-action {
		position: absolute;
		top: 100px;
		right: 0;
		bottom: initial;
		left: initial;
		width: 440px;
		padding: 20px;
	}

	.control-action .option-select .combo-wrap .combo-select .combo-dialog {
		transform-origin: center top 0px;
	}

	.control-action .option-select .combo-wrap .combo-select .combo-dialog {
		top: 140%;
		bottom: auto;
		padding: 10px;
		gap: 5px;
		width: 106%;
	}

	.control-action .option-select .combo-wrap .combo-select .combo-dialog::before {
		top: -8px;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		border-bottom: 5px solid var(--bg-color-secondary);
		border-top: none;
	}

	.control-action .option-select .combo-wrap .combo-select .combo-dialog .color-button {
		width: 15px;
		height: 15px;
	}

}

@media screen and (min-width:1024px) {
	#chapter-article .content-wrap {
		max-width: 970px;
	}

	#chapter-article .chapter-content {
		padding: 20px 40px 40px;
		position: relative;
	}

	#chapter-article .recommends {
		padding: 30px 0;
	}
}

@media screen and (min-width:1587px) {

	#chapter-article .left-panel,
	#chapter-article .right-panel {
		display: block;
		position: absolute;
		top: 0;
		left: -300px;
		height: 100%;
		width: 300px;
	}

	#chapter-article .left-panel .ad-sticky,
	#chapter-article .right-panel .ad-sticky {
		display: block;
		width: 300px;
		height: 700px;
		padding-top: 10px;
	}

	#chapter-article .right-panel {
		left: initial;
		right: -300px;
	}
}

#chapter-article .chapter-content.manga {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(n, 1fr);
	grid-row-gap: 10px;
	direction: rtl;
}

#chapter-article .chapter-content.manga.reversed {
	direction: ltr;
}

#chapter-article .chapter-content.manga p {
	display: contents;
}

#chapter-article .chapter-content.manga img {
	grid-column: span 1;
	grid-row: span 1;
}

#chapter-article .chapter-content.manga h1 {
	grid-column: 1 / 3;
	grid-row: 1;
	text-align: center;
	align-self: center;
}

#chapter-article .chapter-content.manga img.large {
	grid-column: span 2;
	grid-row: span 1;
}

#chapter-article .chapter-content.webtoon h1 {
	text-align: center;
	align-self: center;
}

#chapter-article .chapter-content.webtoon * {
	margin-top: 0;
	margin-bottom: 0;
	padding-top: 0;
	padding-bottom: 0;
	display: block;
}