select.sel {
	font-size: 1.5rem;
	display: block;
	margin-bottom: 20px;
	width: 350px;
	background-color: transparent;
	border: none;
	border-bottom: #f1f1f1 3px solid;
	color: #f1f1f1
}


@media screen and (max-width:768px) {
	select.sel {
		margin: 2em auto;
		width: 80%;
		color: var(--text-color);
		border-bottom: var(--text-color) 3px solid;
		font-size: 1.5em;
	}

}

select.sel option {
	background-color: var(--bg-color);
	color: var(--text-color);
}