#explore {
	padding-bottom: 50px;
	padding-top: 13px
}

#explore .novel-list.horizontal .novel-item .cover-wrap,
#typepage .novel-list.horizontal .novel-item .cover-wrap {
	width: 19.8%
}

#explore .paging,
#latest-updates .paging {
	border-top: 1px solid var(--border-color-4);
	padding-top: .8rem;
	display: flex
}

#explore .paging .pagination-container,
#latest-updates .paging .pagination-container {
	margin: auto
}

#explore .action-header {
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid var(--border-color-4);
	padding-bottom: .8rem;
	margin: .8rem 0 1rem
}

#explore .action-panel .title {
	font-size: 1rem;
	margin-bottom: .8rem;
	padding-bottom: .5rem;
	border-bottom: 1px solid var(--border-color-4);
	display: inline-block
}

#explore .action-panel .action-list {
	font-size: 0;
	width: 100%;
	height: auto
}

#explore .action-panel .action-list li {
	display: inline-block;
	margin-right: .7rem;
	margin-bottom: .7rem
}

#explore .action-panel .action-list li button {
	display: inline-block;
	text-decoration: none;
	padding: 3px 6px;
	border: 1px solid var(--bg-color-secondary);
	background-color: var(--bg-color-secondary);
	color: var(--text-color-secondary);
	border-radius: 5px;
	font-size: .875rem;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility
}

#explore .action-panel .action-list li button:hover,
#explore ul.proplist li a:hover {
	border-color: var(--bg-color-secondary-2)
}

#explore .action-panel .action-list li button.active,
#typepage .sortitem a:hover,
#typepage .sortitem a.selected,
#explore ul.proplist li a.active,
#typepage .sortitem a:hover,
#typepage .sortitem a.selected {
	color: #e7e7e7;
	background-color: var(--bg-color-secondary-2)
}

#explore .action-panel .action-list li button.inactive,
#explore ul.proplist li a.inactive {
	color: rgba(199, 45, 34, 0.977);
}

#explore .action-panel .action-list.mb0 li {
	margin-bottom: 0
}

#explore .action-panel .action-tags {
	display: flex;
	justify-content: space-between;
}

#explore .action-panel .action-tags .combo-box-container {
	width: 7em;
	display:flex;
	justify-content: left;
	align-items: center;
}

#explore .action-panel .action-tags .combo-box-container .combo-box-tags {
	background-color:var(--bg-color-2);
	border : 1px solid var(--text-color);
	border-radius: 5px;
	width: 100%;
	height: 22px;
	color: var(--text-color);
}

#explore .action-panel .action-tags .combo-box-container .combo-box-enter {
	background-color: var(--bg-color-2);
	border: none;
	color: var(--button-bg);
	border-radius: 5px;
	margin-left:2px;
	font-size: 15px;
	height: 22px;
}
#explore .action-panel .action-tags .combo-box-container .combo-box-enter p {
	height: 22px;
}

#explore .action-panel .action-tags .search-button-container .search-button {
	color:var(--button-bg);
	border:none;
	background-color: var(--bg-color-2);
	font-size: 20px;
}
#explore .action-panel .action-tags .search-button-container .search-button:hover {
	color:var(--bg-color-secondary-3);
}

#explore .action-panel .scroll-wrapper {
	height: auto;
	width: 100%;
	overflow-x: auto;
	position: relative;
	margin-bottom: 10px
}



#explore .action-panel .scroll-wrapper::-webkit-scrollbar:vertical,
#explore .scroll-wrapper::-webkit-scrollbar:vertical {
	width: 10px
}

#explore .action-panel .scroll-wrapper::-webkit-scrollbar:horizontal,
#explore .scroll-wrapper::-webkit-scrollbar:horizontal {
	height: 10px
}

#explore .action-panel .scroll-wrapper::-webkit-scrollbar-track,
#explore .scroll-wrapper::-webkit-scrollbar-track {
	background: var(--bg-color-4)
}

#explore .action-panel .scroll-wrapper::-webkit-scrollbar-thumb,
#explore .scroll-wrapper::-webkit-scrollbar-thumb {
	background-color: var(--bg-color-secondary);
	box-shadow: inset 2px 2px 4px 0 rgba(135, 135, 135, .3);
	border-radius: 10px
}

#explore .action-panel .scroll-wrapper::-webkit-scrollbar-thumb:hover,
#explore .scroll-wrapper::-webkit-scrollbar-thumb:hover {
	background: var(--bg-color-secondary-2)
}

#explore .action-panel .filters {
	display: flex;
	flex-direction: column
}

#explore .action-panel .filters .filter-item {
	margin-bottom: 15px
}

#explore .tags-wrapper ul.proplist li a {
	font-size: .825rem
}

#latest-updates,
#typepage {
	padding: 1rem 0
}

#latest-updates header,
#typepage header {
	padding: 0 0 20px;
	margin-bottom: 20px;
	position: relative;
	border-bottom: 1px solid var(--border-color-4)
}

#typepage .actions {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	margin-top: 20px;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start
}

#typepage .paging {
	-webkit-box-flex: 0;
	-ms-flex-positive: 0;
	flex-grow: 0;
	-ms-flex-negative: 1;
	flex-shrink: 1;
	margin-top: 20px
}

#typepage .paging .pagination {
	justify-content: flex-start
}

#typepage .sortitem {
	display: block;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-ms-flex-negative: 0;
	flex-shrink: 0
}

#typepage .sortitem a {
	font-size: 1rem;
	display: inline-block;
	text-decoration: none;
	position: relative;
	padding-bottom: 6px;
	padding: 5px 10px;
	color: var(--anchor-color);
	border: 1px solid var(--bg-color-secondary-2);
	border-radius: 0 6px
}

#typepage .sortitem a+a {
	margin-left: 10px
}

@media screen and (min-width:768px) {
	#explore {
		background-color: var(--bg-color);
		padding-top: 0
	}

	#explore .container {
		background-color: var(--bg-color-2);
		padding: 26px
	}

	#explore header {
		justify-content: space-between;
		align-items: flex-start
	}

	#explore header h1 {
		font-size: 24px;
		font-weight: 600
	}

	#explore .novel-list.horizontal .novel-item .cover-wrap,
	#typepage .novel-list.horizontal .novel-item .cover-wrap {
		width: 22%
	}

	#explore .action-panel .title {
		font-size: 20px;
		margin-bottom: 12px;
		padding-bottom: 6px
	}

	#explore .scroll-wrapper ul.proplist {
		width: 100%
	}

	#explore .action-panel .action-list li {
		margin-right: 13px;
		margin-bottom: 13px
	}

	#explore .action-panel .action-list li button,
	#explore .tags-wrapper ul.proplist li a,
	#explore .scroll-wrapper ul.proplist li a {
		font-size: 16px
	}

	#explore .action-panel .scroll-wrapper {
		overflow-x: hidden;
		margin-bottom: 13px
	}

	#explore .action-panel .filters,
	#typepage .actions {
		flex-direction: row
	}

	#explore .action-panel .filters .filter-item+.filter-item {
		margin-left: 26px
	}

	#explore .action-panel .action-tags .combo-box-container {
		width: 15%;
	}
	


	#latest-updates,
	#typepage {
		background-color: var(--bg-color);
		padding: 0
	}

	#latest-updates .container,
	#typepage .container {
		padding: 26px;
		background-color: var(--bg-color-2)
	}

	#typepage .paging {
		margin-top: 0
	}

	#explore .scroll-wrapper {
		overflow-x: hidden;
		padding-top: 10px
	}

	#latest-updates .paging {
		justify-content: flex-start
	}

	#latest-updates header h1 {
		font-size: 26px;
		line-height: 30px
	}

	#latest-updates header p {
		font-size: 18px
	}
}

@media screen and (min-width:1024px) {

	#explore .novel-list.horizontal .novel-item .cover-wrap,
	#typepage .novel-list.horizontal .novel-item .cover-wrap,
	#explore .novel-list.horizontal .novel-item .cover-wrap,
	#typepage .novel-list.horizontal .novel-item .cover-wrap {
		width: 17%
	}
}

#explore {
	margin-top: 30px;
	padding-bottom: 50px
}

#explore header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	position: relative;
	padding-bottom: 10px;
	margin: 20px 0;
	border-bottom: 2px solid var(--border-color-4)
}

#explore header .paging {
	margin-top: 1rem
}

#explore h4.proptitle {
	font-size: 20px;
	margin-bottom: 20px;
	border-bottom: 2px solid var(--border-color-3);
	padding-bottom: 8px;
	display: inline-block
}

#explore ul.proplist {
	font-size: 0
}

#explore ul.proplist li {
	display: inline-block;
	margin-right: 12px;
	margin-bottom: 15px;
	font-size: 0
}

#explore ul.proplist li a {
	text-decoration: none;
	padding: 3px 6px;
	border: 1px solid var(--bg-color-secondary);
	background-color: var(--bg-color-secondary);
	color: var(--text-color-secondary);
	border-radius: 5px;
	font-size: 16px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility
}

#explore .scroll-wrapper {
	height: auto;
	width: 100%;
	overflow-x: auto;
	position: relative;
	padding-top: 10px;
	margin-bottom: 10px
}

#explore .scroll-wrapper .proplist {
	width: 1000px;
	height: auto
}

#latest-updates .paging {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center
}

#latest-updates,
#typepage {
	padding-bottom: 1rem;
	padding-top: 1rem
}

#latest-updates header,
#typepage header {
	padding: 0 0 20px;
	margin-bottom: 40px;
	position: relative;
	border-bottom: 2px solid var(--border-color-4)
}