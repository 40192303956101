.novel-item-featured {
    display: flex;
    flex-direction: row;
    padding-left: 2%;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    width:70%;
    margin-top: 20px;
}

.featured-column {
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    aspect-ratio: 1.618;
}

.featured-column .novel-title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
}

.featured-column:first-child {
    align-items: flex-start;
}

.scroll {
    overflow-y: auto;
    max-height: 100%;
    scrollbar-width: thin;
    overflow-x: hidden;
    max-width: 100%;
}

.item-body.featured-column {
    margin-left: 10px;
}

.featured-column span {
    padding-top: 10px;
    margin-top: auto;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.item-body.featured-column i {
    font-style: normal;
    padding-right: 20px;
}

.novel-list {
    width: 100%;
}

.item-cover.featured-column .novel-cover {
    width: 100%
}

@media screen and (max-width:768px) {
    .featured-column .novel-title {
        font-size: 1rem;
        margin-bottom: 0;
        margin-top: -5px;
    }

    .novel-item-featured {
        width:100%;
        margin-top: 0px;
    }

    .item-body.featured-column i {
        padding-right: 0;
        font-size: .75rem;
        margin-top: -4px;
    }

    .featured-column span {
        padding-top: 8px;
    }

    .featured-column p {
        font-size: .75rem;
    }

    .item-body.featured-column {
        width: 60%;
    }

    .item-cover.featured-column {
        width: 36%;
    }
}