.switch {
    margin-right: 1em;

    position: relative;
    display: inline-block;
    width: 3em;
    height: 1.4em;
}


.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}


.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--text-color-4);
    -webkit-transition: .2s;
    transition: .2s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 1em;
    width: 1em;
    left: 0.2em;
    bottom: 0.2em;
    background-color: var(--text-color);
    -webkit-transition: .2s;
    transition: .2s;
}

input:checked+.slider {
    background-color: var(--button-bg);
}

input:focus+.slider {
    box-shadow: 0 0 1px var(--button-bg);
}

input:checked+.slider:before {
    -webkit-transform: translateX(1.4em);
    -ms-transform: translateX(1.4em);
    transform: translateX(1.4em);
}


.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}