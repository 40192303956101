#chpagedlist {
	display: flex;
	flex-direction: column
}

.pagination-container {
	display: block
}

.pagination {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding-left: 0;
	border-radius: 4px
}

.pagination>li {
	display: inline
}

.pagination>li>a,
.pagination>li>span {
	position: relative;
	float: left;
	padding: 6px 12px;
	margin-left: -1px;
	line-height: 1.428571429;
	text-decoration: none;
	background-color: var(--bg-color-3);
	border: 1px solid var(--border-color-4);
	font-size: 18px
}

.pagination>li.big a {
	font-size: 30px;
	line-height: 22px;
	padding: 5px 0;
	font-weight: 700
}

.pagination>li:first-child>a,
.pagination>li:first-child>span {
	margin-left: 0;
	border-bottom-left-radius: 4px;
	border-top-left-radius: 4px
}

.pagination>li:last-child>a,
.pagination>li:last-child>span {
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px
}

.pagination>li>a:hover,
.pagination>li>span:hover,
.pagination>li>a:focus,
.pagination>li>span:focus {
	background-color: var(--bg-color-secondary-2);
	border-color: var(--bg-color-secondary-4);
	color: #f1f1f1
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
	z-index: 2;
	color: #f1f1f1;
	cursor: default;
	background-color: var(--bg-color-secondary-2);
	border-color: var(--bg-color-secondary-4)
}

.pagination>.disabled>span,
.pagination>.disabled>a,
.pagination>.disabled>a:hover,
.pagination>.disabled>a:focus {
	color: #999;
	cursor: not-allowed;
	background-color: #fff;
	border-color: #ddd
}

.pagination-lg>li>a,
.pagination-lg>li>span {
	padding: 10px 16px;
	font-size: 18px
}

.pagination-lg>li:first-child>a,
.pagination-lg>li:first-child>span {
	border-bottom-left-radius: 6px;
	border-top-left-radius: 6px
}

.pagination-lg>li:last-child>a,
.pagination-lg>li:last-child>span {
	border-top-right-radius: 6px;
	border-bottom-right-radius: 6px
}

.pagination-sm>li>a,
.pagination-sm>li>span {
	padding: 5px 10px;
	font-size: 12px
}

.pagination-sm>li:first-child>a,
.pagination-sm>li:first-child>span {
	border-bottom-left-radius: 3px;
	border-top-left-radius: 3px
}

.pagination-sm>li:last-child>a,
.pagination-sm>li:last-child>span {
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px
}

.pager {
	padding-left: 0;
	margin: 20px 0;
	text-align: center;
	list-style: none
}

.pager:before,
.pager:after {
	display: table;
	content: " "
}

.pager:after {
	clear: both
}

.pager:before,
.pager:after {
	display: table;
	content: " "
}

.pager:after {
	clear: both
}

.pager li {
	display: inline
}

.pager li>a,
.pager li>span {
	display: inline-block;
	padding: 5px 14px;
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 15px
}

.pager li>a:hover,
.pager li>a:focus {
	text-decoration: none;
	background-color: #eee
}

.pager .next>a,
.pager .next>span {
	float: right
}

.pager .previous>a,
.pager .previous>span {
	float: left
}

.pager .disabled>a,
.pager .disabled>a:hover,
.pager .disabled>a:focus,
.pager .disabled>span {
	color: #999;
	cursor: not-allowed;
	background-color: #fff
}

#explore .pagination {
	justify-content: flex-start
}

@media screen and (min-width:140px) {

	.pagination>li>a,
	.pagination>li>span {
		padding: 5px 10px;
		font-size: 16px
	}
}

.pagefilter {
	display: flex;
	width: 100%;
	justify-content: space-between;
}

.pagefilter .sort-button {
	font-size: 28px;
	height: 22px;
	border: none;
	color: var(--button-bg);
	background-color: transparent;
	cursor: pointer;
}

.pagefilter .sort-button:hover {
	color: var(--bg-color-secondary-3);
}


.pagefilter .sort-button .dropdown-menu {
	position: absolute;
	display: flex;
	flex-direction: column;
	font-size: 0.7em;
	background-color: var(--bg-color-2);
	z-index: -1;
	border: 2px solid var(--border-color-4);
	border-radius: 4px;
	opacity: 0;
	transition: all .1s ease-in-out;
}

@media screen and (max-width: 768px) {
	.pagefilter .sort-button .dropdown-menu {
		left: calc(100% - 75px);
	}

}

.pagefilter .sort-button .show {
	z-index: 2;
	opacity: 1;
}

.pagefilter .sort-button .dropdown-menu .dropdown-item {
	color: var(--text-color);
	padding-left: 10px;
	padding-right: 10px;
}

.pagefilter .sort-button .dropdown-menu .dropdown-item:hover {
	color: var(--text-color-2);
	background-color: var(--bg-color-3);
	padding-left: 15px;
	padding-right: 5px;
}

.pagefilter .sort-button .dropdown-menu .dropdown-item:first-child {
	padding-top: 5px;
}

.pagefilter .sort-button .dropdown-menu .dropdown-item:last-child {
	padding-bottom: 5px;
}